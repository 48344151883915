.song-add {
    padding: 10px;
}
.song-add div {
    margin: 15px 0px;
    align-content: center;
}

.song-add select {
    background-color: transparent;
    border: 1px solid #000;
    width: 100px;
    padding: 5px;
    outline: none;
    border-radius: 5px;
    margin: 0px 10px;
}

.song-add h5 {
    margin: 5px 0px;
}

/* .song-add input, textarea {
    background-color: transparent;
    width: 200px;
    padding: 5px;
    outline: none;
    border: 1px solid #000;
    border-radius: 5px;
} */
/* .song-left-box button  {
    text-align: center;
    background-color: #3a65ff;
    padding: 5px 10px;
    border: 1px solid #3a65ff;
    color: #fff;
    width: 150px;
    border-radius: 25px;
} */
.song-left-box{
    margin: 8px;
    display: flex;
    justify-content: flex-end;
  }
/* .create-song-btn{
    background-color:   #3a65ff;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    padding: 6px 14px ;
    margin: 8px;
    display: flex;
    justify-content: flex-end;
    margin: 8px;
    display: flex;
    justify-content: flex-end;
  } */

  .song-input-box{
      display: flex;
      flex-direction: column;
      margin: 5px;
  }
 
  .song-input-box input {

      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
  }
  .song-input-box select {

    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
  .song-input-box textarea{
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
  }
  .song-row{
      display: flex;
  }

  .viewSongList-btn{
      background-color: greenyellow;
      
  }