

 @-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.shimmer-wrapper {
  position: relative;
  overflow: hidden;
  background: #f6f7f8;
  background-image: -webkit-linear-gradient(
    left,
    #eeeeee 8%,
    #dddddd 18%,
    #eeeeee 33%
  );
  background-image: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  animation: placeHolderShimmer 1.5s infinite;
  width: 100%;
  height: 100%;
}

.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  animation: placeHolderShimmer 2s infinite;
}

.line {
  height: 20px;
  background: #f6f7f8;
  margin-bottom: 16px;
  animation: placeHolderShimmer 1.5s infinite;
}
