.slider-container {
  height: 80vh;
  background-color: #f5f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
}


.slider-box {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 600px;
  height: 54vh;
  text-align: center;
}

.slider-box h2 {
  color: #2a3b67;
  margin-bottom: 15px;
  font-size: 24px;
}

.slider-box p {
  color: #4a4a4a;
  margin-bottom: 10px;
  line-height: 1.6;
}


.slider {
  width: 100%;
  margin: 20px 0;
}


.font-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.font-controls button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.font-controls button:hover {
  background-color: #357abd;
}

.font-controls .current-size {
  color: #2a3b67;
  font-size: 20px;
}


@media (max-width: 1024px) {
  .slider-container{
    height: max-content;
  }
  .slider-box {
    width: fit-content;
    padding: 20px;
    height: fit-content;
    
  }

  .slider-box h2 {
    font-size: 22px;
  }

  .font-controls button {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}
/* Mobile styling */
@media (max-width: 600px) {
  .slider-container {
    height: auto;
    padding: 15px;
  }

  .slider-box {
    width: 100%;
    padding: 15px;
    height: fit-content;
    border-radius: 8px;
  }

  .slider-box h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .slider-box p {
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 1.4;
  }

 
  .slider {
    margin: 15px 0;
  }

 
  .font-controls {
    margin-top: 10px;
  }

  .font-controls button {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

  .font-controls .current-size {
    font-size: 16px;
  }
}
