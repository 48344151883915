.songProjector {
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background-size: 400% 400%;
  /* animation: gradient 15s ease infinite; */
  min-height: 95vh;
  background-color: #000;
}
.songProjector .close-button {
  font-size: 1.5em;
  cursor: pointer;
  height: 35px;
  width: 35px;
  float: right;
  text-align: center;
  color: #fff;
  /* background-color: red; */
}

.songProjector .close-button:hover {
  color: white;
  background-color: red;
}
.lyrics-mal {
  text-align: center;
  font-size: 26px;
  width: 100%;
}
.lyrics-eng {
  text-align: center;
  font-size: 26px;
  width: 100%;
}
