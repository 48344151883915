.lyrics-view {
  position: relative;
  background-color: #000;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
}

.songProjector {
  background-size: 400% 400%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  }
.song-content, .lyrics-view, .verse-view-verses {
  white-space: pre-wrap;
  text-align: center;
}

.song-content {
  height: 100vh;
  color: #ffffff;
  text-align: center;
  font-size: 2.3em; 
  padding-top: 300px; 
  width: 50vw;
  max-width: 800px; 
  margin: 0 auto;
}

.verse-view-content {
  width: 100%;
  position: relative;
  max-width: 100%;
}


.verse-view-content .close-buttone, 
.verse-view-content .safariBrowser {
  position: absolute;
  top: 240px;
  right: -150px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.song-view-control {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button {
  position: absolute;
  top: 40%;
  color: #fff;
  font-size: 2.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  z-index: 10;

}

.icon-button[value="prev"] {
  position: fixed;
  left: 50px;
}

.icon-button[value="next"] {
  position: fixed;
  right: 50px;
}

.verse-view-verses {
   display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
  text-shadow: -0.5px -0.5px 0 black, 0.5px -0.5px 0 black, -0.5px 0.5px 0 black, 0.5px 0.5px 0 black;
  overflow-y: auto;
}

.song-projection {
  margin: 1.3em 0;
  line-height: 1.2;
  overflow-y: scroll;
}

.modal.verse-view-modal {
  z-index: 9999;
}


.verse-view-modal .header,
.verse-view-modal .song-project {
  display: flex;
  justify-content: space-between;
}

.back-to-song-btn {
  max-width: 35px;
  max-height: 35px;
  display: flex;
  color: #3a65ff;
  background-color: white;
  border: none;
  margin: 10px;
  transition: 0.5s;
  padding: 5px;
}

.close-buttone, .safariBrowser {
  position: absolute;
  top: 200px !important;
  right: 100px  !important;
  font-size: 35px;
  padding: 0px 6px;
  min-width: 60px;
  background-color: #3a65ff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  z-index: 9999;
}

.close-buttone:hover, 
.safariBrowser:hover {
  color: white;
  background-color: red;
  border: none;
  cursor: pointer;
}


@media (max-width: 1024px) {
  .song-content {
    font-size: 2.8em;
    padding-top: 250px;
     width: 70%;
     
  }
  .icon-button {
    font-size: 2rem;
  }
  .icon-button[value="prev"] {
    left: 40px;
  }
  .icon-button[value="next"] {
    right: 40px;
  }
  .close-buttone, .safariBrowser {
    top: 180px !important;
    right: 30px !important;
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .song-content {
    font-size: 1.6em;
    padding-top: 150px;
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 420px;
  }
  .icon-button {
    font-size: 1rem;
  }
  .icon-button[value="prev"] {
    left: 20px;
    top: 400px;
    padding: 2px 4px;
  }
  .icon-button[value="next"] {
    right: 20px;
    top: 400px;
    padding: 2px 4px;
  }
  .close-buttone, .safariBrowser {
    top: 250px !important;
    right: -50px !important;
    font-size: 1.4rem;
  }
}

@media (max-width: 820px) and (min-width:820px){
  .song-content {
    font-size: 1.8em;
    padding-top: 150px;
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 420px;
  }
  .icon-button {
    font-size: 1rem;
  }
  .icon-button[value="prev"] {
    left: 20px;
    top: 400px;
    padding: 2px 4px;
  }
  .icon-button[value="next"] {
    right: 20px;
    top: 400px;
    padding: 2px 4px;
  }
  .close-buttone, .safariBrowser {
    top: 250px !important;
    right: -50px !important;
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  .song-content {
    font-size: 1em;
    padding-top: 300px;
    width: 60%;
  }
  .icon-button {
    font-size: 0.9rem;
  }
  .icon-button[value="prev"] {
    left: 20px;
  }
  .icon-button[value="next"] {
    right: 20px;
  }
  .close-buttone, .safariBrowser {
    top: 200px !important;
    right: -10px  !important;
    font-size: 0.4rem;
    padding: 1px 2px;
  }
}

@media (max-width: 320px) {
  .song-content {
    font-size: 1em;
    padding-top: 80px;
    width: 70%;
  }
  .icon-button {
    font-size: 0.7rem;
  }
  .icon-button[value="prev"]{left: 20px;} 
  .icon-button[value="next"] {
    right: 20px;
  }
  .close-buttone, .safariBrowser {
    top: 50px !important;
    right: 7px!important;
    font-size: 0.4rem;
    
  }
}

.modal.verse-view-modal {
  z-index: 9999;
 
}