.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(58, 101, 255);
  border-bottom: 1px solid rgb(223, 225, 230);
  font-family: system-ui;
  padding: 0 10px;
}

.app-bar a {
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
}

.app-bar .primary {
  display: flex;
  justify-content: space-between;
}

.app-bar .logo-link {
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 1.2em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  } 

.app-bar .logo-link span {
  text-transform: capitalize;
}

.app-bar .logo-link img {
  height: 60px;
}

.app-bar .secondary-toggle {
  display: none;
}

.app-bar .app-bar-secondary {
  display: flex;
}

.app-bar-secondary a {
  margin: 0 10px;
}

.app-bar .nav-links {
  display: flex;
  align-items: center;
}

.app-bar .nav-link {
  color: rgb(255, 255, 255);
  text-align: center;
}

.app-bar .nav-link.active {
  background-color: rgb(28, 65, 202);
}

.app-bar .nav-link:hover {
  background-color: rgb(28, 65, 202);
  text-shadow: 
  1px 1px 2px rgba(0, 0, 0, 0.5),  
  2px 2px 4px rgba(0, 0, 0, 0.3),  
  3px 3px 6px rgba(0, 0, 0, 0.1);}


.app-bar .auth-links {
  display: flex;
  align-items: center;
}

.app-bar .auth-link {
  margin: 0 5px;
}

.app-bar .login-link {
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
 }

.textShadow{
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3),  
  2px 2px 4px rgba(0, 0, 0, 0.2),  
  3px 3px 6px rgba(0, 0, 0, 0.1); 
}

.app-bar .register-link {
  background-color: rgb(255, 255, 255);
  color: rgb(31, 38, 103);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2),  
  2px 2px 4px rgba(0, 0, 0, 0.1),  
  3px 3px 6px rgba(0, 0, 0, 0.1); 
}

.app-bar .user-section {
  margin-right: 5px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding: 5px 0;
}

.app-bar .user-button {
  font-size: 1rem;
  font-weight: bold;
  border: none;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid rgb(255, 255, 255);
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 15px;
 box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.3), 0px 3px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.app-bar .user-section:hover .user-links {
  display: flex;
  flex-direction: column;
}

.app-bar .user-links {
  position: absolute;
  top: 68px;
  right: 34px;
  width: 100px;
  border-radius: 0 0 5px 5px;
  display: none;
  flex-direction: column;
  align-items: stretch;
}

.app-bar .user-link-button {
  color: white;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1em;
  text-transform: uppercase;
  background-color: #3a65ff;
  font-weight: bolder;
 
}

.app-bar .user-link-button:hover {
  background-color: red;
}

.app-bar .mobile-navbar {
  display: none;
}
.appbar-mobile-div {
  display: none;
}
.MuiDrawer-paper {
  background-color: #3a65ff !important;
  color: white !important;
}

.user-email {
  font-size: 0.7em; 
  color: #fff;
  text-align: center;
  margin-top: 4px;
  }

  .admin-panel{
    
    display: flex;
    align-items: center;
  }

  .admin-link{
    color: #ffffff;
   font-size: 1em;
}

  .app-bar .admin-link.active {
  background-color: rgb(28, 65, 202);
   text-shadow: 
  1px 1px 2px rgba(0, 0, 0, 0.4),  
  2px 2px 4px rgba(0, 0, 0, 0.3),  
  3px 3px 6px rgba(0, 0, 0, 0.1);}

/* Mobile only styles */
@media (max-width: 1200px) {
  .app-bar {
    display: block;
  }
  .appbar-mobile-div {
    display: block;
  }
  .app-bar .secondary-toggle {
    display: block;
  }

  .app-bar .app-bar-secondary {
    display: block;
  }

  .app-bar .app-bar-secondary.hidden {
    display: none;
  }

  .app-bar .app-bar-secondary .nav-links {
    display: flex;
    flex-direction: column;
  }

  .app-bar .mobile-navbar {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgb(58, 101, 255);
    padding: 0 10px;
  }

  .app-bar .mobile-navbar .top {
    display: flex;
    justify-content: space-between;
  }

  .app-bar .mobile-navbar .links {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 5px;
    
  }

  .app-bar .mobile-navbar .page-links {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .app-bar .mobile-navbar .links {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .app-bar .nav-link {
    padding: 10px 15px;
  }
  .app-bar .user-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.text-center {
  text-align: center;
}

p {
  text-align: justify;
}