.main-div {
  margin: 20px;
  max-width: 800px;
}

.map {
  text-align: center;
  margin: 10px auto;
}

.map-title {
  margin: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.map-title-input {
  border-radius: 4px;
  border-width: 1px;
  margin-left: 2px;
  min-height: 30px;
  min-width: 300px;
  margin-top: 8px;
}

.map-image-preview-content {
  margin: 20px auto;
}
