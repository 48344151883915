.admin-dashboard-page {
  display: flex;
  height: 90vh;
}

.admin-dashboard-page .modal-content {
  background-color: white;
}

.admin-dashboard-page .button {
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
}

.admin-dashboard-page .button.primary.blue {
  color: white;
  background-color: #3a65ff;
}

.admin-dashboard-page .button.secondary.blue {
  color: #3a65ff;
  background: none;
  box-sizing: border-box;
  border: 1px solid #3a65ff;
}
.admin-dashboard-page .button.primary.blue.add {
  margin: 0 0 0 10px;
}
.admin-sidebar {
  display: flex;
  flex-direction: column;
  background-color: #c4d1ff;
  min-height: 100%;
  min-width: 200px;
}
.admin-dashboard-page .main-section {
  width: 100%;
  overflow: auto;
}
.admin-sidebar .item {
  padding: 10px 15px;
  text-decoration: none;
  color: black;
}

.admin-sidebar .item:hover,.admin-sidebar .active {
  background-color: #3a65ff;
  color: white;
}
