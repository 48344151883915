body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@supports (padding-top: env(safe-area-inset-top)) {
    body {
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }
}
@keyframes blinkingBackground {
  0% {
    background-color: #f2f5ff;
  }

  25% {
    background-color: #dee6ff;
  }

  50% {
    background-color: #f2f5ff;
  }

  75% {
    background-color: #dee6ff;
  }

  100% {
    background-color: #f2f5ff;
  }
}

.mobile-only {
  display: none !important;
  background-color: transparent !important;
  border: none !important;
  align-items: center;
}

.hidden {
  display: none !important;
}

.box {
  background-color: #f2f5ff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #c4d1ff;
  box-shadow: rgb(3 27 78 / 10%) 0px 2px 4px;
  width: 250px;
}

.box-header {
  display: flex;
  justify-content: space-between;
  background-color: #c4d1ff;
  padding: 0 10px;
}

.box-header h2 {
  margin: 0;
  padding: 5px;
  font-size: 1vw;
  cursor: default;
}

.box-header h6 {
  margin: 0;
  padding: 5px;
  cursor: default;
  align-items: center;
  font-size: 18px;
}

.box-content {
  padding: 5px;
  background-color: white;
  overflow-y: auto;
}

.select-versions {
  margin: 10px;
}

.version-button {
  background-color: rgb(58, 101, 255);
  color: #fff;
  margin: 5px;
  border: none;
  padding: 5px;
  width: 60px;
  border-radius: 20px;
}

.box-footer {
  text-align: right;
  padding: 5px;
}

.guest-message {
  max-width: 500px;
  padding-top: 25px;
  margin: auto;
  font-size: large;
  font-weight: 600;
  text-align: center;
}

.modal {
  display: flex;
  align-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  margin: auto;
}

.page-contents {
  padding: 10px;
  display: flex;
}

.page-contents .box {
  margin: 5px;
  flex: 1;
}

.quill {
  display: flex;
  flex-direction: column;
}

.bible-page .ql-container {
  overflow-y: auto;
}

@media (max-width: 1200px) {
  .mobile-only {
    display: block !important;
    background-color: #3a65ff !important;
    color: #fff !important;
  }

  .mobile-hidden {
    display: none !important;
  }

  .box {
    width: 100%;
    height: 100%;
  }

  .box-header h2 {
    margin: 0;
    padding: 5px;
    font-size: 2vw;
    cursor: default;
  }

  .aside {
    display: flex;
    align-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 850px) {
  .box-header h2 {
    margin: 0;
    padding: 5px;
    font-size: 3vw;
    cursor: default;
  }

  .page-contents .box {
    margin: 0;
    flex: 1;
  }
}

#form-dialog-title .MuiTypography-h6 {
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.float-right {
  float: right;
  margin: 10px 5px;
}

.paddingX {
  padding: 10px 30px;
}

.close {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.form_item {
  display: flex;
  flex-direction: column;
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
