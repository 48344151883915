.contact-page .error {
  color: red;
}

.contact {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}
.contact-page {
  border-radius: 4px;
  position: relative;
  padding: 15px;
  width: 400px;
  box-shadow: 0px 6px 12px 3px rgba(0, 0, 0, 0.2);
}
h1 {
  font-weight: 600px;
  font-size: 21px;
  text-align: center;
  margin: 5px 0 5px 0;
}
.input {
  background-color: transparent;
  padding: 5px 0;
  margin: 5px 0 5px 0;
}
.contact-page label {
  font-weight: 600;
}
.contact-page .gotopage {
  text-align: center;
  font-weight: 500;
}

.contact-page .message {
  background-color: #48d648;
  color: white;
  text-align: center;
  display: block;
  padding: 5px;
  font-size: 1em;
  border-radius: 5px;
}
