.article-box {
  background-color: white;
}
.user-article {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
}
.user-title {
  min-width: 170px;
  overflow-x: auto;
  padding: 10px;
 
}
.article-title{
  display: flex; 
}

/* .author-input-titles{
  margin-left: 30%; 
} */
.title-head {
  text-align: center;
}
.user-content {
  overflow-x: auto;
  text-align: justify;
  padding: 10px;
}
.user-credit {
  text-align: right;
  font-weight: 700;
}

.user-atricle-title-btn{
    min-width: 120px;
    max-width: 120px;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #3A65FF;
    background: transparent;
    margin: 5px;
    transition: 0.5s;
}
.user-atricle-title-btn:hover{
  background:  #C4D1FD;
}

.index{
  margin: 5px 0;
  
}