.dictionary-box {
    background-color: #f2f5ff;
}

/* .dictionary-box .box-body {
} */

.dictionary-box .dictionary-tab-buttons {
    display: flex;
}

.dictionary-box .dictionary-tab-button {
    padding: 5px 10px;
    cursor: pointer;
    width: 100%;
}

.dictionary-box .dictionary-tab-button.active {
    /* border-bottom: 1px solid #c4d1ff; */
    background-color: #3a65ff;
    color: #fff;
    text-transform: uppercase;
}

.dictionary-box .dictionary-tab {
    max-height: 100vh;
    padding: 10px 15px;
}

.dictionary-box .dictionary-index-tab{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.dictionary-box .dictionary-index-tab .words {
    overflow-y: auto;
    padding: 5px;
    text-align: justify;
}

.dictionary-box .dictionary-search-tab .dictionary-search-form {
    width: 100%;
    display: flex;
    overflow-y: auto;
}

.dictionary-box .dictionary-search-tab .dictionary-search-form input[type="text"] {
    width: 100%;
    box-sizing: border-box;
}

.dictionary-box .dictionary-search-tab .dictionary-search-form .search-button {
    padding: 10px 15px;
    background-color: #3a65ff;
    color: white;
    border: none;
    cursor: pointer;
}

.dictionary-box .dictionary-search-tab .search-results {
    height:59vh ;
    overflow-y: auto;
    text-align: justify;
}