.book-select-column {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.book-select-row {
  display: flex;
  /* flex-direction: row; */
  gap: 15px;
  justify-content: space-evenly;
  align-items: center;
  /* margin-inline: 10px; */
}

.admin-chapter-title-submit-button-flex {
  display: flex;
  justify-content: right;
}
.marginX {
  margin: 15px auto;
}
