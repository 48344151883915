.verse-view-modal {
  background-color: #000;
  background-size: 400% 400%;
  padding: 50px;
}
.modal.verse-view-modal {
  z-index: 9999;
}
.verse-view-verses {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
  text-shadow: -0.5px -0.5px 0 black, 0.5px -0.5px 0 black, -0.5px 0.5px 0 black, 0.5px 0.5px 0 black;
  padding-right:30px;
  padding-bottom: 50px;
  overflow-y:scroll; 
}

.verse-view-content {
  width: 100%;
  position: relative;
  max-width: 100%;
}

.verse-view-modal .header,
.verse-view-modal .song-project {
  display: flex;
  justify-content: space-between;
}

.verse-view-modal .close-button {
    position: absolute; 
    top: 0px; 
    right: 10px; 
    font-size: 28px; 
    padding: 4px 2px; 
    min-width: 60px; 
    background-color: #3a65ff; 
    color: white; 
    border-radius: 5px; 
    cursor: pointer;
    z-index: 9999; 
    transition: background-color 0.3s ease; 
     text-align: center;
}

.verse-view-modal .close-button:hover,
.safariBrowClsBtn:hover {
  background-color: red;
  color: white;
}

.verse-view-controls,
.song-view-control {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  color: white;
}

.verse-view-controls:hover,
.song-view-control:hover {
  opacity: 1;
}

.verse-view-controls button,
.verse-view-controls select,
.song-view-control button,
.song-view-control select {
  padding: 5px;
  margin: 5px;
  background: none;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
}

.verse-view-controls option,
.song-view-control option {
  color: black;
}

.verse-view-verse {
  display: flex;
  justify-content: space-between;
}

.verse-view-verses .version {
  text-align: left;
  flex: 1;
}

.verse-view-verses .version-verse {
  font-size: 2rem;
}

.safariBrowClsBtn {
 position:absolute ; 
    /* top: 5px;  */
    right: 80px; 
    font-size: 25px; 
    padding: 4px 8px; 
    min-width: 40px; 
    background-color: #3a65ff; 
    color: white; 
    border-radius: 5px; 
    cursor: pointer;
    z-index: 9999; 
    transition: background-color 0.3s ease; 
    text-align: center;
}

.icon-button .MuiSvgIcon-root {
  font-size: 2.5rem;
}

@media only screen and (max-width: 768px) {
  .song-project {
    height: 100%;
  }
  .verse-view-verses .version-verse {
    font-size: 1.5rem;
  }
  .verse-view-verses{
    padding-right: 30px;
  }
}




.next-button, .prev-button{
  width: 48px;
  
  font-size: 15px;
  text-align: center;
 
}
.next-button:hover, .prev-button:hover,.slctverpersld:hover{
  background-color:#3a65ff; 
  color:#ffffff;
  border:#3a65ff;
}
.verseOptions:hover{
  background-color:#3a65ff; 
  color:#ffffff;
  border: 1px solid #3a65ff;
}
.safariBrowVerOpt{
  width: 90px;
  height: 30px;
  font-size:14px}
.safariBrowVerOpt:hover , .safariBrowslctverpersld:hover{ background-color:#3a65ff; 
  color:#ffffff;
  border: 1px solid #3a65ff;}

  .safariBrowslctverpersld{
  width: 70px;
  height: 30px;
  font-size: 14px;
  }
 

   @media (max-width:480px) and (min-width:390px) {
     .perslide-text{
    font-size: 10px;
   }
   .next-button,.prev-button{
    width: 20px;
    height: auto;
    font-size: 10px;
   }
   .safariBrowVerOpt {
     width: 60px;
     font-size: 8px;
   }
   .safariBrowslctverpersld{
  width: 40px;
  height:30px;
  font-size:8px}
  .close-button{
    position: absolute;
    top: 5px !important;
    min-width: 35px !important;
    font-size: 18px !important;
  }
  .verse-view-verses{
    padding: 10px;
  overflow-y: scroll;
  word-break: break-word;
  font-size: 1rem !important;
  }
  .verse-view-modal{
    padding: 10px;
  }
  }

  @media (max-width:390px) and (min-width:320px) {
     .perslide-text{
    font-size: 8px;
   }
   .next-button,.prev-button{
    width: 20px;
    height: auto;
    font-size:8px;
   }
   .safariBrowVerOpt {
     width: 55px;
     font-size: 8px;
   }
   .safariBrowslctverpersld{
  width: 40px;
  /* height:30px; */
  font-size:8px}
  .close-button{
    position: absolute;
    top: 5px !important;
    right: 0 !important;
    min-width: 30px !important;
    font-size: 12px !important;
  }
  .verse-view-verses{
    padding: 12px;
  overflow-y: scroll;
  word-break: break-word;
  font-size: 1rem !important;
  }
  .verse-view-modal{
    padding: 12px;
  }
  }


@media(min-width: 540px) and (max-width: 540px){
  .close-button{
 position: absolute; 
    top: 30px !important; 
    right: 0px!important  ;
    font-size: 18px !important;
    min-width: 40px !important ;
  }
}

@media (max-width:1024){
  .verse-view-verses{
    font-size: 30px !important;
  }
}

/* For iOS Mobiles */

.ios-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow-y: auto;
  z-index: 9999;
  padding: 10px;
  }

.ios-fullscreen .verse-view-content {
  max-height: 100%;
  padding: 20px;
  overflow-y: scroll;
  word-break: break-word;
}

@media (max-width: 520px) and (min-width:415px){
  .safariBrowClsBtn{
    position: absolute;
    right: 30px !important;
    top: 23px !important;
    font-size: 19px; 
    padding: 3px 7px; 
    min-width: 25px; 
  }
  .ios-fullscreen .version-verse {
    font-size: 1rem;
    overflow-y: scroll;
}
}
@media (max-width: 414px) and (min-width:373px){
  .safariBrowClsBtn{
    position: absolute;
    right:20px !important;
    top: 24px !important;
    font-size: 19px; 
    padding: 3px 7px; 
    min-width: 25px; 
  }
  .ios-fullscreen .version-verse {
    font-size: 1rem;
    overflow-y: scroll;
}
}