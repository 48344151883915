.note-add-form textarea {
    width: 100%;
    box-sizing: border-box;
    resize: none;
}

.note-add-box .note-error {
    color: red;
}

.note-add-box .note-add-form {
    height: 90%;
    display: flex;
    flex-direction: column;
}

.note-add-box .quill {
    height: 100%;
}