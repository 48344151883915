
.settings-layout {
  display: flex;
  height: 100vh;
}


.settings-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f5f7fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  margin: 30px 10px 30px 30px;
}


.settings-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
}


.settings-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-size: 16px;
  color: rgb(41, 40, 40);
}

.settings-button .button-icon {
  margin-right: 10px;
}

.settings-button .expand {
  margin-left: auto;
}


.settings-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}


.home-setting-font-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.navigation-buttons {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navigation-buttons:hover {
  background-color: rgb(58, 101, 255);
  color: #ffffff;
}


.logo-home {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}


.child-heading {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}


.children {
  padding: 4px;
  border-radius: 5px;
  flex-grow: 1;
  overflow-y: auto;
}


.settings-button:hover {
  background-color: rgb(58, 101, 255);
  color: #ffffff;
}

.active {
  background-color: rgb(58, 101, 255);
  color: #ffffff;
}

.active:hover {
  background-color: #ffffff;
  color: rgb(58, 101, 255);
  border: 1px solid rgb(58, 101, 255);
}

/* Tablets and Smaller Laptops */
@media (max-width: 1024px) {
  .settings-sidebar {
    width: 200px;
    padding: 15px;
    height: 700px;
  }

  .settings-button {
    font-size: 14px;
    padding: 8px;
  }

  .navigation-buttons {
    padding: 8px 12px;
  }
}

.arrow-back{
  cursor: pointer;
}

/* Mobile css */


.mobile-settings-header {
  display: flex;
  align-items: center;
  padding: 10px;
  
}

.mobile-arrow-back {
  cursor: pointer;
  font-size: 24px;
  margin-right: 10px;
}

.mobile-settings-header h1 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}


.mobile-home-setting-buttons {
  display: flex;
  justify-content: center; 
  align-items: center;
  margin: 15px auto; 
  padding: 5px 0;
 
  width: 80%; 
  max-width: 300px; 
}


.mobile-navigation-buttons {
  color: #333;
  text-decoration: none;
  padding: 10px;
  font-size: 1em;
  
}

.mobile-navigation-buttons:hover{
  color: #ffffff;
  background-color: #0073e6;
  border-radius: 5px;
}

.mobile-navigation-buttons img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.mobile-active {
  font-weight: bold;
  color: #ffffff;
  background-color: #0073e6; 
  border-radius: 5px;
}
.mobile-active:hover{
  font-weight: bold;
  color: #0073e6;
  background-color: #ffffff; 
  border-radius: 5px;
    border:2px solid #0073e6;
}



.mobile-settings-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 10px 15px;
  background-color: #0073e6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: 80%; 
  text-align: center;
}

.mobile-settings-button .mobile-button-icon {
  margin-right: 8px;
}

.mobile-expand {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 1.5em;
}
