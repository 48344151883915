.sermon-page {
  height: 89vh;
  overflow: auto;
  padding: 15px 30px;
}
.sermon-view .view-header {
  margin: 0;
  text-align: center;
}

.sermon-view .sermon-meta {
  font-style: italic;
}
