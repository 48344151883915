.song-view{
    text-align: center;
}
.song_page {
    padding: 20px 30px;
    justify-content: center;
}
.song_page_content {
    display: flex;
    justify-content: space-evenly;
}
.song_page_content select {
    border: unset;
    border-bottom: 1px solid #3a65ff;
    background: transparent;
    padding: 8px 0px;
    outline: none;
    cursor: pointer;
    font-size: .9em;
    width: 250px;
}
.song {
    display: flex;
    justify-content: center;
    /* margin: 30px; */
}
.songs {
    display: flex;
    justify-content: center;
}
.song-sheet {
    border: 1px solid #1C41CA;
    margin: 15px 10px;
    /* width: 85%; */
}
.song-title {
    text-align: center;
    background-color: #C4D1FD;
    padding: 5px;
}
h3 {
    margin: 0px;
}
.song-lyric {
    padding: 30px;
    display:flex;
    justify-content: space-evenly;
}
.song-lang-1 {
    border: 1px solid #fff;
    width: 600px;
    text-align: center;
}
.song-lang-1 p {
    border: 2px solid #fff;
    font-size: 17px;
    padding: 10px;
    line-height: 1.50;
    margin: 1px;
    background-color: #f2f2f2;
    text-align: left;
}
.song-lang-1 p:nth-child(even) {
    background-color: #F2F5FF;
}
.song-lang-2 {
    border: 1px solid #fff;
    width: 600px;
    text-align: center;
}
.song-lang-2 p {
    border: 2px solid #fff;
    font-size: 18px;
    padding: 10px;
    margin:1px;
    line-height: 1.4;
    background-color: #f2f5ff;
    text-align: left;
}
.song-lang-2 p:nth-child(even) {
    background-color: #f2f2f2;
}


@media (max-width: 800px) {
    .song_page {
        margin: 5px 10px;
    }
    .song_page_content {
        display: block;
    }
    .song_page_content select {
        font-size: .8em;
        width: 300px;
        margin-bottom: 15px;
        padding: 0px;
    }
    .song{
        margin: 10px 0px;
    }
    .song-lyric {
        padding: 0px;
        display: block;
    }
    .song-sheet {
        width: auto;
    }
    .song-lang-1 {
        width: 400px;
    }
    .song-lang-2 {
        width: 400px;
    }
}
/* background-color: rgb(51, 96, 254); */
.icon-btns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 0;
}
.icon-btns title {
    background: #c4c4c4;
}
.icon-btn {
    width: 35px;
    /* background-color: #606060; */
    /* border-radius: 25px; */
}
.icon-btns .back {
    color:  #606060;
}
.icon-btns .edit {
    color: #009D27;
}
.icon-btns .delete {
    color: #E14F4D;
}
