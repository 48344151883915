.user-article-view {
    display: flex;
    justify-content: center;
}
.user-article-sheet {
    width: 95%;
}
.user-article-title {
    text-align: center;
    padding: 5px;
    width: 100%;
}

.back-to-article-btn{
    max-width: 35px;
    max-height: 35px;
    display: flex;
    color: #3a65ff;
    border: none;
    padding: 5px;
    
}
.user-article-main {
    background-color: #f2f5ff
}