.confirmation-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.confirmation-confirm-button {
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    background-color: #3f51b5;
    color: white;
    border: 1px solid #3f51b5;
    border-radius: 15px;
}

.confirmation-cancel-button {
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    background: none;
    color: #3f51b5;
    border: 1px solid #3f51b5;
    border-radius: 15px;
}