.create-verse-btn {
    text-align: center;
    background-color: #3a65ff;
    padding: 5px 10px;
    border: 1px solid #3a65ff;
    color: #fff;
    width: 150px;
    border-radius: 25px;
    cursor: pointer;
}

.table-title-truncate {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}