.prayer-list-page {
  /* padding: 20px 40px; */
  height: 89vh;
  overflow: auto;
}
.MuiDialog-paperWidthSm {
  min-width: 500px !important;
}
.prayer-card {
  padding: 10px;
}
.prayer-card h4 {
  margin: 5px 0;
}
.display-inline {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.prayer-list-page .container {
  margin: 20px;
}
.prayer-list-header {
  text-align: center;
}
.prayer-list-page .prayer-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.prayer-list-page .prayer-options-item {
  display: flex;
  align-items: center;
}

.prayer-list-page .prayer-list-header {
  text-align: center;
}

.prayer-list-header button {
  padding: 10px 15px;
}

.prayer-list-page .add-new-button {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #3a65ff;
  border-radius: 15px;
  background-color: #3a65ff;
  color: white;
  font-size: 0.9em;
}
.prayer-list-page .create-group-button {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #3a65ff;
  background-color: #3a65ff;
  color: white;
  border-radius: 15px;
  font-size: 0.9em;
}

.prayer-request {
  padding: 10px;
  border: 1px solid #c4d1ff;
  margin: 10px;
}

.prayer-request:hover {
  background-color: #ecf0ff;
  box-shadow: 0 4px 8px 0 #aaaaaa;
  border: none;
}

.prayer-request .title {
  margin: 0;
  width: 150px;
}

.prayer-request .meta {
  font-style: italic;
  font-size: small;
  margin: 5px 0px;
  width: 150px;
}
.prayer-request .body {
  margin: 0;
  width: 300px;
}
.prayer-list-page select {
  padding: 5px 10px;
  border: 1px solid #3a65ff;
  cursor: pointer;
  background-color: #fff;
}
.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 18%;
  padding: 0;
}
.dialog-box {
  border: 1px solid #3a65ff;
  padding: 10px;
  font-size: 21px;
}
/* .prayer-list {
  max-width: px;
} */

.prayer-request .MuiIconButton-root {
  padding: 0px;
  margin: 2px;
}
.prayer-request .space-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
#form-dialog .button {
  text-align: center;
  background-color: #3a65ff;
  margin: 5px 20px;
  border: 1px solid #3a65ff;
  border-radius: 25px;
  padding: 5px 10px;
  float: right;
}
.create-button {
  background-color: #f2f5ff;
}
#form-dialog select {
  display: block;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid rgba(196, 196, 196, 1);
  cursor: pointer;
  font-size: 0.9em;
  border-radius: 4px;
  width: 100%;
}

#form-dialog select:hover {
  background-color: #f6f8ff;
  border: 1px solid #3a65ff;
}

@media (max-width: 800px) {
  .prayer-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .prayer-list .prayer-request {
    width: 80%;
  }
  .prayer-list-page .prayer-options {
    display: block;
    justify-content: center;
    align-items: center;
  }
  .MuiDialog-paperWidthSm {
    min-width: 100% !important;
  }
  .prayer-list-page .prayer-options-item {
    margin: 1px auto;
    display: flex;
    justify-content: center;
  }
}
.MuiOutlinedInput-multiline {
  padding: 8px !important;
}

.desc-dialog-padding {
  padding: 10px 15px
}
