.note-list-item {
  padding: 10px 5px;
}

.note-list-item img {
  max-width: 500px;
  margin: auto;
}

.note-list-item:hover {
  background-color: #f2f5ff;
}

.note-list-box .verse-index {
  font-size: small;
  font-style: italic;
}

.note-list-box .note-accordion-content {
  display: flex;
  flex-direction: column;
}

.box-content select {
  padding: 8px 16px;
  border: 1px solid rgba(196, 196, 196, 1);
  cursor: pointer;
  background-color: transparent;
}
.box-content select:hover {
  background-color: #f6f8ff;
  border: 1px solid #3a65ff;
}
.box-content .button {
  text-align: center;
  width: auto;
  background-color: #3a65ff;
  color: #fff;
  padding: 8px 16px;
  margin: 10px auto;
  border: 1px solid #3a65ff;
  border-radius: 4px;
  text-transform: uppercase;
}
.box-content .notes {
  margin: 10px;
}
.float-right {
  float: right;
}
/* .display-inline {
  display: inline-block;
} */
.event-options {
  display: flex;
  justify-content: space-between;
}
.MuiIconButton-root {
  padding: 0px;
  margin: 2px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}