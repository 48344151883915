.sermon-create {
  max-width: 800px;
  margin: 10px auto;
}
.sermon-create .create-header {
  display: flex;
  align-items: center;
}

.sermon-create .back-button {
  background: none;
  border: none;
}

.sermon-create .create-header h1 {
  text-align: center;
}

.sermon-create .row {
  display: flex;
}

.sermon-create .row .item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.sermon-create .error {
  color: red;
}

.sermon-create .save-button {
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
}

.sermon-create .rdw-image-modal {
  right: 5px;
  left: auto;
}
.item .note-add-form {
  height: 90%;
  display: flex;
  flex-direction: column;
}
.sermon-create .button {
  text-align: center;
  background-color: #3a65ff;
  color: #fff;
  padding: 8px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #3a65ff;
  border-radius: 4px;
  float: right;
}
.sermon-create .input {
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid rgba(196, 196, 196, 1);
  cursor: pointer;
  font-size: 0.9em;
  border-radius: 5px;
  /* width: 100%; */
}
.sermon-create .input:hover {
  background-color: #f6f8ff;
  border: 1px solid #3a65ff;
}
.sermon-create textarea {
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid rgba(196, 196, 196, 1);
  cursor: pointer;
  font-size: 0.9em;
  border-radius: 5px;
  /* width: 100%; */
}
.sermon-create textarea:hover {
  background-color: #f6f8ff;
  border: 1px solid #3a65ff;
}
/* .create-body {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
} */