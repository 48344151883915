.commentary-box {
    background: #f2f5ff;
    height: 73vh;
    border: 0;
    overflow: auto;
}
.close-buttonn{
    font-size: 30px;
    color:rgb(255, 0, 0);
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
}
@media (max-width: 800px) {
    .commentary-box {
        height: 100%;
    }
}
.close-buttonn:hover{
    background-color: rgb(255, 0, 0);
    color: #f2f5ff;
    padding: 0px 8px;
}