.back-button{
    background-color: transparent;
    border: none;
    align-items: center;
    text-align: center;
    font-size: 14px;
     display: flex; /* Ensures icon and text align horizontally */
    gap: 4px; /* Adds space between the icon and text */
    cursor: pointer; 
    padding: 4px 5px;
}
.bckbtn{
    display: flex;
     flex-direction: row;
     align-items: center; 
     padding: 10px 0px 0px 10px;
}
.back-button:hover{
      background-color:#3a65ff;
      color: white;
    border-radius: 5px;
}