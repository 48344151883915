.Search-article {
  margin: 10px auto;
}

.create-article-btn button {
  background-color: #3a65ff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 14px;
  margin: 8px;
  display: flex;
  justify-content: flex-end;
}
.create-article-btn {
  margin: 8px;
  display: flex;
  justify-content: flex-end;
}

.delete-btn-article-div {
  display: flex;
  justify-content: center;
}

.delete-btn-article {
  /* background-color: #e21c1c; */
  background-color: #ff3333;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 14px;
  align-items: center;
}

.delete-btn-article:hover {
  background-color: #e60000;
}

.main-background {
  min-width: 100%;
  min-height: 91vh;
  background-color: #f2f5ff;
}

.user-article-body {
  padding: 30px 44px;
}

.article-Search-bar {
  display: flex;
  justify-content: space-between;
}

.article-table-data {
  background-color: white;
  width: 600px;
  border: none;
  border-radius: 8px;
  display: flex;
  margin-bottom: 6px;
}
.article-table-row {
  border: none;
  border-radius: 8px;
  flex-direction: column;
}

.article-table {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .main-background {
    min-height: 100vh;
  }
  .user-article-body {
    max-width: 360px;
    margin-right: 60px;
  }

  .article-table {
    min-width: 300px;
  }
  .article-table-data {
    min-width: 180px;
  }
  .Search {
    max-width: 200px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .article-table-data {
    min-width: 300px;
  }
  .main-background {
    min-height: 100vh;
  }
  .user-article-body {
    max-width: 360px;
    margin-right: 60px;
  }

  .article-table {
    min-width: 300px;
  }

  .Search {
    max-width: 200px;
  }
}
