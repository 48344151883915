.prayer-request-edit-form .row {
    display: flex;
    justify-content: space-between;
}

.prayer-request-edit-form .item {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.prayer-request-edit-form select {
    display: block;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid rgba(196,196,196,1); 
    cursor: pointer;
    font-size: .9em;
    border-radius: 4px;
    width: 100%;
  }
  
  .prayer-request-edit-form select:hover {
    background-color: #f6f8ff;
    border: 1px solid #3a65ff; 
  }