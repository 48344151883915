.MuiOutlinedInput-input {
  padding: 8px 16px !important;
  /* width: 150px !important; */
}

.calendar-page h6 {
  font-weight: 600px;
  font-size: 15px;
  text-align: center;
  margin: 5px 0 5px 0;
}

.close-button {
  font-size: 30px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.event-options {
  display: flex;
  justify-content: flex-start;
}

.calendar-page .display-inline {
  display: inline-block;
}

.icon {
  padding: 0px;
  margin: 2px;
  color: #757575;
}

.calendar-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 10px auto;
}

.dialog-msg {
  display: flex;
  justify-content: flex-end;

  margin: 0 26px 10px;
}

.margin-input {
  margin: 15px 0;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  text-align: center;
}

.create-event-input-flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.create-event-input-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.calendar-page .MuiIconButton-root {
  padding: 5px;
}

.calendar-container {
  position: relative;
}

.custom-button {
  display: none;
  /* Hide the button by default for larger screens */
}

@media (max-width: 768px) {
  .custom-button {
    display: block;
    /* Show the button for smaller screens */
    text-align: center;
    margin-bottom: 10px;
  }
}

.fc .fc-toolbar.fc-header-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

/* Style for the inner div containing the buttons in full calendar */
.fc-toolbar-chunk > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
}
