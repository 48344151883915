.sermon-edit {
  max-width: 800px;
  margin: 10px auto;
}

.sermon-edit .edit-header {
  display: flex;
  align-items: center;
}

.sermon-edit .back-button {
  background: none;
  border: none;
}

.sermon-edit .edit-header h1 {
  margin: 0;
}

.sermon-edit .row {
  display: flex;
}

.sermon-edit .row .item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.sermon-edit .error {
  color: red;
}

.sermon-edit .save-button {
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
}
.sermon-edit .button {
  text-align: center;
  background-color: #3a65ff;
  color: #fff;
  padding: 8px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #3a65ff;
  border-radius: 4px;
  float: right;
}
.sermon-edit input {
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid rgba(196, 196, 196, 1);
  cursor: pointer;
  font-size: 0.9em;
  border-radius: 5px;
}
.sermon-edit input:hover {
  background-color: #f6f8ff;
  border: 1px solid #3a65ff;
}
.sermon-edit textarea {
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid rgba(196, 196, 196, 1);
  cursor: pointer;
  font-size: 0.9em;
  border-radius: 5px;
}
.sermon-edit textarea:hover {
  background-color: #f6f8ff;
  border: 1px solid #3a65ff;
}
