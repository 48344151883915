.main-bg {
  background-color: #f2f5ff;
  min-height: 91vh;
}
.main-head {
  display: flex;
  margin-top: 3%;
  /* margin-left: 10%; */
  justify-content: center;
}
.head-items {
  margin-left: 16%;
  margin-top: 24px;
}
.admin-card {
  background: #ffffff;
  /* padding: 20px; */
  border-radius: 10px;
  margin-top: 5px;
  border: hidden;
  width: 100%;
}

.create-song-btn button {
  background-color: #3a65ff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 14px;
  margin: 8px;
  display: flex;
  justify-content: flex-end;
}
.create-song-btn {
  margin: 8px;
  display: flex;
  justify-content: flex-end;
}
.admin-card table {
  border-collapse: collapse;
  width: 100%;
}
.admin-card td,
th {
  border: 4px solid #f2f5ff;
  padding: 10px;
  width: auto !important;
}
.song-number {
  text-align: center;
}

.popover {
  background-color: #fff;
  padding: 15px 20px;
}
.popover-title {
  font-weight: 600;
}
.p1 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding-left: 22px;
}
.p2 {
  font-size: 14px;
  text-align: center;
}

.deletepopover-btn {
  justify-content: space-between;
}
.cancel-btn {
  background-color: #948f8f;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 6px 24px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #5e5b5b;
}
.confirm-btn {
  background-color: #4caf50;
  /* background-color:  #ff3333; */
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  margin-left: 20px;
  margin-bottom: 8px;
  padding: 6px 24px;
  cursor: pointer;
}
.confirm-btn:hover {
  background-color: rgb(33, 126, 5);
}

.Search-box {
  border: 1px solid #6c6c6c;
  box-sizing: border-box;
  border-radius: 10px;
  height: 50px;
  outline: none;
  padding: 5px;
  display: flex;
  align-items: center;
  background-color: white;
  cursor: pointer;
  border: none;
  max-width: 500px;
  width: 100%;
}
.Search-input {
  border: 1px solid #6c6c6c;
  box-sizing: border-box;
  height: 48px;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  /* color: #CACACA; */
  padding: 8px;
  width: 100%;
}
.Search-input:focus {
  border: none;
}
.Search {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 100%;
}
.user-song-body {
  padding: 20px 150px;
}

.root {
  margin: 10px auto;
}

@media (max-width: 1200px) {
  .user-song-body {
    padding: 20px;
  }
}
