.sermon-index {
  padding: 20px 40px;
}

.sermon-head {
  align-items: center;
  text-align: center;
}

.sermon-index .index-body {
  margin: auto;
}

.sermon-index .sermons {
  display: flex;
  flex-direction: column;
}

.sermon {
  margin: 5px;
  padding: 15px;
  border: 1px solid #3a65ff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.sermon:hover {
  background-color: #ecf0ff;
  box-shadow: 0 4px 8px 0 #aaaaaa;
}

.sermon-index .sermon:first-letter {
  text-transform: uppercase;
}

.sermon-index .sermon .title {
  font-size: 18px;
  font-weight: 600;
  width: 300px;
  /* text-transform: capitalize; */
}

.sermon-index .sermon .summary::first-letter {
  text-transform: capitalize;
}

.summary {
  width: 700px;
  text-align: justify;
}

.date {
  width: 100px;
}

@media (max-width: 800px) {
  .sermon {
    display: block;
    margin: 5px;
    padding: 5px 10px;
  }
  .date,
  .sermon-index .sermon .title,
  .summary {
    width: 100%;
  }
}
