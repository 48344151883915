.about-page {
  height: 89vh;
  overflow: auto;
}
.about-page .container {
  max-width: 70%;
  margin: auto auto 35px auto;
}
.about-page ul {
  list-style-type: none;
}
.about-page .text-center {
    text-align: center;
}
.about-page .about {
    padding: 15px;
}
.about-page .content {
  text-align: justify;
}
