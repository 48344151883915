.search-box .box-content {
    overflow-y: auto;
}

.search-box .search-form {
    padding: 5px;
    background-color: #f2f5ff;
}

.search-box input,
.search-box select,
.search-box label {
    box-sizing: border-box;
    margin: 0;
    padding: 1px;
}

.search-box select {
    display: block;
    box-sizing: border-box;
    border: 1px solid #3a65ff;
    cursor: pointer;
    font-size: .9em;
}

.search-box label {
    margin-left: 5px;
}

.search-box .row{
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.search-limit-item {
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;
}

.search-limit-item label {
    width: 100%;
    cursor: pointer;
}

.search-box .book-range {
    display: flex;
    align-items: center;
    flex: 1;
}

.search-box .search-version-label {
    display: none;
}

.search-box .search-version-select {
    font-size: 1.2em;
    border-right: 0;
}

.search-box .search-term-input{
    width: 100%;
    font-size: 1.2em;
    border: 1px solid #3a65ff;
    border-right: 0;
    border-left: 0;
}

.search-box .search-submit-input {
    background-color: #3a65ff;
    color: white;
    border: 0;
    cursor: pointer;
    padding: 5px;
}

.search-error {
    color: red;
}

.search-box .print-button{
    background-color: #3a65ff;
    color: white;
    border: 0;
    cursor: pointer;
    padding: 5px;
}

.search-box .search-results {
    overflow-y: auto;
    font-size: .9em;
}

.search-results-header {
    display: none;
}
.search-print-count {
    text-align: center;
    font-style: italic;
}

@media print {
    .search-results-header {
        display: block;
    }
}

.search-result {
    cursor: pointer;
    padding: 10px 5px;
}

.search-result:hover {
    background-color: #f2f5ff;
}

.search-result-index {
    font-style: italic;
    font-weight: 500;
}

.search-result-highlight {
    background-color: #c4d1ff;
}