.login {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}
.register {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}
.login-page {
  border-radius: 4px;
  position: relative;
  padding: 15px 30px;
  width: 300px;
  box-shadow: 0px 6px 12px 3px rgba(0, 0, 0, 0.2);
}
.register-page {
  border-radius: 4px;
  position: relative;
  padding: 15px 30px;
  width: 300px;
  box-shadow: 0px 6px 12px 3px rgba(0, 0, 0, 0.2);
}
.login h2 {
  text-align: center;
}
.input {
  background-color: transparent;
  padding: 5px 0;
  margin: 5px 0 5px 0;
}
.login, .register label {
  font-weight: 600;
}
.gotopage {
  text-align: center;
  font-weight: 500;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
