.dictionary-main-page {
    padding: 10px;
}

.dictionary-main-page .header {
    display    : flex;
    gap        : 15px;
    align-items: center;
}

.dictionary-main-page .words-section {
    display       : flex;
    flex-direction: column;
    gap           : 10px;
}

.dictionary-main-page .word-item {
    padding       : 5px;
    display       : flex;
    flex-direction: column;
    gap           : 5px;
}

.dictionary-main-page .word-item p {
    margin: 0;
}

.dictionary-main-page .word-item:hover {
    background-color: #f2f5ff;
}

.dictionary-main-page .word-item-word {
    font-size  : 1em;
    font-weight: 600;
}

.dictionary-main-page .word-item-options {
    display: flex;
    gap    : 5px;
}

.dictionary-main-page .add-word-form {
    padding  : 10px;
    min-width: 500px;
}

.dictionary-main-page .add-word-form .form-header {
    display        : flex;
    justify-content: space-between;
    align-items    : flex-start;
    margin-bottom  : 10px;
}

.dictionary-main-page .add-word-form h2 {
    margin: 0;
}

.dictionary-main-page .add-word-form .close-button {
    padding  : 5px;
    font-size: 1.2em;
    cursor   : pointer;
}

.dictionary-main-page .add-word-form .form-body {
    display       : flex;
    flex-direction: column;
    gap           : 5px;
}

.dictionary-main-page .add-word-form .row {
    display: flex;
}

.dictionary-main-page .add-word-form .item {
    display       : flex;
    flex-direction: column;
    align-items   : stretch;
    width         : 100%;
}

.dictionary-main-page .add-word-form input[type="text"],
.dictionary-main-page .add-word-form textarea {
    padding  : 5px 10px;
    font-size: 1em;
}

.dictionary-main-page .add-word-form textarea {
    min-height: 200px;
}

.dictionary-main-page .delete-form {
    padding       : 15px;
    display       : flex;
    flex-direction: column;
    gap           : 15px;
}

.dictionary-main-page .delete-form .delete-form-buttons {

    display: flex;
    gap    : 10px;
}
.filter-section select {
 margin: 0px 10px;
}
.row-end {
    display: flex;
    justify-content: flex-end;
}
