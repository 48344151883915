.songs-input-box select {
  padding: 12px 20px;
  margin: 8px 0;
  margin-right: 10px;
  display: inline-block;
  border-right: 1px solid #ccc;
  /* border-radius: 4px;
    box-sizing: border-box; */
  background-color: white;
  /* border:none; */
  border-top: none;
  border-left: none;
  border-bottom: none;
  outline: none;
}