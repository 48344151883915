.bible-page .box-content {
  height: 100%;
}

/* Only for desktops */
@media (min-width: 800px) {
  .bible-page .page-contents {
    max-height: 75vh;
  }
}
