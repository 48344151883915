.verseMain-div{
   text-align: center;
}

.title{
    font-weight: bold;
}
.verse-submit-btn{
    text-align: center;
    background-color: #3a65ff;
    margin-top: 8px;
    padding: 5px 10px;
    border: 1px solid #3a65ff;
    color: #fff;
    width: 170px;
    border-radius: 25px;
    cursor: pointer;
}

.verse-box{
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    padding:5px;
    background-color: white;
}