.bible-box .box-header h2 {
  flex: 1;
  text-align: center;
  overflow-y: auto;
}

.bible-box .box-content {
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 220px);
}
.bible-box .box-content {
  height: 98%;
}

.bible-box .bible-verse {
  text-align:start;
  word-break:break-word;
}

.bible-box .bible-verse:nth-child(odd) {
  background: #f2f5ff;
}


.test {
  border: 1px solid #000;
}
.bible-box .blinking-verse {
  background-color: #f2f5ff;
  animation: blinkingBackground 1.5s infinite;
}

.bible-box .verse-options {
  display: none;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 5px;
  
}

.bible-box .verse-options .primary-options,
.bible-box .verse-options .secondary-options {
  display: flex;
  align-items: center; 
}

.bible-box .verse-options .secondary-options {
  visibility: hidden;
  border: 1px solid #4f8ac6;
}

.bible-box .bible-verse:hover .verse-options {
  display: flex;
}

.bible-box .bible-verse:hover .verse-options .secondary-options {
  visibility: visible;
  opacity: 0.3;
}

.bible-box .bible-verse:hover .verse-options .secondary-options:hover {
  visibility: visible;
  opacity: 1;
}

.bible-box .highlight-buttons {
  font-size: 0.9em;
}
.bible-box .other-options button {
  font-size: 0.8em;
  background: none;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}

.bible-box .highlight-buttons button {
  cursor: pointer;
  }

.bible-box .highlight-buttons .highlight-blue-button {
  background-color: #4f8ac6;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 5px 5px;
}

.bible-box .highlight-buttons .highlight-green-button {
  background-color: #73be69;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 5px 5px;
}
.bible-box .highlight-buttons .highlight-rose-button {
  background-color: #eb618b;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 5px 5px;
}
.bible-box .highlight-buttons .highlight-gold-button {
  background-color: #fec752;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 5px 5px;
}

.bible-box .highlight-buttons .highlight-none-button {
  border: none;
  height: 20px;
  width: 20px;
  padding: 0px;
  margin: 5px 5px;
  background-color: transparent;
  border: none;
}

.bible-box .versions {
  display: flex;
}

.bible-box .version {
  flex: 1;
  padding: 7px;
  border-right: 1px solid #c4d1ff;
  border-left: 1px solid #c4d1ff;
}
.bible-box .version .xs {
  font-size: 12px;
}
.bible-box .version .sm {
  font-size: 14px;
}
.bible-box .version .md {
  font-size: 16px;
}
.bible-box .version .lg {
  font-size: 18px;
}
.bible-box .version .xl {
  font-size: 20px;
}
.bible-box .version-malayalam .xs {
  font-size: 9px;
}
.bible-box .version-malayalam .sm {
  font-size: 11px;
}
.bible-box .version-malayalam .md {
  font-size: 13px;
}
.bible-box .version-malayalam .lg {
  font-size: 15px;
}
.bible-box .version-malayalam .xl {
  font-size: 16px;
}

.bible-box .verse-number {
  font-weight: 600;
  margin-right: 5px;
}
.bible-box .version-malayalam {
  text-align: start;
}

.small {
  font-size: 12px;
}

.medium {
  font-size: 16px;
}

.large {
  font-size: 20px;
}
.verse-text {
  color: #000;
}

.bible-box .other-options .MuiSvgIcon-root {
  height: 20px;
  width: 20px;
}
.bible-box .note-button {
  font-size: 0.8em;
  background: none;
  box-sizing: border-box;
  border: none;
}
.chapter-title {
  background-color: #fff;
  font-style: italic;
  /* font-size: 1.25em; */
  border: 1px solid #c4d1ff;
  padding: 10px;
  max-height: 30px;
  overflow: auto;
}

.swiper-slide {
  opacity: 0 !important;
}

.swiper-slide-active {
  opacity: 1 !important;
}
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  overflow: clip;
  list-style: none;
  padding: 0;
  z-index: 0 !important;
  display: block;
}

.swiper {
  width: 100%;
}

.error-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
}
.projectorIcon{
  width: 25px;
  height: 26px;
}
.error-message {
 display: flex;
 
  text-align: center;
  background-color: #f7d3d5;
  color: #d11a2c;
  padding: 15px;
  border: 1px solid #f7d3d5;
  border-radius: 5px;
  margin: 10px 0;
  position: relative;
  width: 100%;
  max-width: 600px; 
  box-sizing: border-box;
}

.icon {
  margin-right: 10px;
  font-size:13px;
  text-align: center;
}

@media (max-width: 768px) {
  .error-message {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .error-message {
    max-width: 95%;
    padding: 10px;
    font-size: 14px;
  }
}

 .toast-middle {
  margin-top: 332px;
}
@media (min-width:820px){
  .toast-middle {
  margin-top: 432px;
}}
@media (min-width:1024px){
  .toast-middle {
  margin-top:370px;
  font-size: 19px;
 }}

 .swipeLeftChapter{
          position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 10;
            background-color: rgb(0 123 255 / 51%);
            border-radius: 50%;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.75;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3)
}
.swipeRightChapter{
   position: absolute;
          top: 50%;
            opacity: 0.75;
          right: 15px;
          transform: translateY(-50%);
          cursor: pointer;
          z-index: 10;
          background-color: rgb(0 123 255 / 51%);
          border-radius: 50%;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
 .biblefullscreen {color: rgb(10, 41, 156);
  position: absolute;
  right: 0;
  font-size: 30px !important;
}
 .biblefullscreen:hover{
  color: #005c31;
  font-weight: bolder;
  /* font-size: 25px; */
 }
@media (min-width: 610px) and (max-width: 1200px) {
    .biblefullscreen {
        /* font-size: 1.5rem;
        text-align: center; */
        margin: 5px auto; 
        font-size: 36px !important;
         position: absolute;
  right: 0;
    }
}
 .bible-box .fullcontent{
     height: 100vh;
     padding: 0;
    overflow-y: auto;
    max-height: 95vh;

  }

  .books-popup {
  position: absolute;
  top:7% !important;
  left: 0% !important;
  z-index: 1000;
  overflow-y: auto;
}

@media  (min-width:319px) and (max-width:375px){
  .books-popup {
 position: absolute;
 top: 0% !important; 
 left: 0% !important;
  z-index: 9999;
  max-height: 78vh !important;  

   }
   .biblefullscreen{
    font-size: 25px !important;
   }
}
@media (min-width:375px)and (max-width: 480px) {
.books-popup {
 position: absolute;
 top: 2% !important; 
 left: 0% !important;
  z-index: 9999;
  max-height: 80vh !important;  
   }

}
.fullScreenBibleContent{
 position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
  }
 

  @media (min-width: 850px) and (max-width: 1200px) {
   .books-popup {
   position: absolute;
   top:9% !important; 
 left: 0% !important;
  z-index: 9999;
  overflow-y: auto;
  max-height: 90vh !important;  
  
   }
}
 @media(min-width:481px) and (max-width:629px){
  .books-popup{
position: absolute;
  top:10% !important;
  left: 0% !important;
    overflow-y: auto;
     z-index: 9999;
  max-height: 80vh !important;  
 }}

 @media(min-width:630px) and (max-width:850px){
  .books-popup{
position: absolute;
  top:10% !important;
  left: 0% !important;
    overflow-y: auto;
     z-index: 9999;
  max-height: 89vh !important;  }
 }


.shimmer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 25%, rgba(245, 245, 245, 0.6) 50%, rgba(255, 255, 255, 0.8) 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  z-index: 10;
  
}
.swiper {
  position: relative;
  
}
.mySwiper {
  transition: opacity 150ms ease-in-out;
}
