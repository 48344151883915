@import "./components/AppBar/AppBar.css";
@import "./components/ArticleBox/article.css";
@import "./components//BibleBox/BibleBox.css";
@import "./components/BiblePageOptions/BiblePageOptions.css";
@import "./components/CommentaryBox/CommentaryBox.css";
@import "./components/ConfirmationDialog/ConfirmationDialog.css";
@import "./components/DictionaryBox/DictionaryBox.css";
@import "./components/EventEditForm/EventEditForm.css";
@import "./components/NoteAddBox/NoteAddBox.css";
@import "./components/NoteEditBox/NoteEditBox.css";
@import "./components/NoteListBox/NoteListBox.css";
@import "./components/PrayerRequestEditForm/PrayerRequestEditForm.css";
@import "./components/SearchBox/SearchBox.css";
@import "./components/SermonCreate/SermonCreate.css";
@import "./components/SermonEdit/SermonEdit.css";
@import "./components/SermonIndex/SermonIndex.css";
@import "./components/SermonView/SermonView.css";
@import "./components/TorreyBox/TorreyBox.css";
@import "./components/VerseViewModal/VerseViewModal.css";

@import "./pages/about/about.css";
@import "./pages/admin/AdminArticlePage/AdminArticleCreate.css";
@import "./pages/admin/AdminArticlePage/UserArticleList.css";
@import "./pages/admin/AdminArticlePage/UserArticleView.css";
@import "./pages/admin/AdminChapterTitle/AdminVerse.css";
@import "./pages/admin/AdminChapterTitle/AdminVerseCreate.css";
@import "./pages/admin/AdminDashboardPage/AdminDashboardPage.css";
@import "./pages/admin/DictionaryMainPage/DictionaryMainPage.css";
@import "./pages/admin/SongAdmin/SongAdmin.css";
@import "./pages/admin/SongAdmin/SongsList.css";
@import "./pages/admin/SongAdmin/SongView.css";
@import "./pages/auth/loginPage.css";
@import "./pages/BiblePage/BiblePage.css";
@import "./pages/CalendarPage/CalendarTempPage.css";
@import "./pages/ContactPage/ContactPage.css";
@import "./pages/PrayerListPage/PrayerListPage.css";
@import "./pages/PrayerListPage/PrayerGroupList.css";
@import "./pages/SongPage/songPage.css";
@import "./pages/SongPage/SongProjection.css";
@import "./pages/SongPage/UserSongList.css";
@import "./pages/SongPage/UserSongView.css";
@import "./pages/admin/AdminMapPage/AdminMapCreate.css";
@import "./pages/admin/AdminChapterTitle/AdminChapterCreate.css";

.error {
  color: red;
}
.grid-container {
  display: grid;
  gap: 1px;
  background-color: #2196f3;
  padding: 1px;
}
.grid-container1 {
  display: grid;
  gap: 1px;
  background-color: #2196f3;
  padding: 1px;
}
.grid-item {
  background-color: white;
  text-align: center;
}

.item1 {
  grid-column: 1 / span 2;
  grid-row: 3;
}
.item6 {
  grid-column: 1;
  grid-row: 1 / span 2;
}
.item7 {
  grid-column: 2 / span 3;
  grid-row: 1;
}
.item8 {
  grid-column: 2 / span 3;
  grid-row: 2;
}
.item9 {
  grid-column: 1 / span 2;
  grid-row: 5;
}
.item3 {
  grid-column: 1 / span 2;
  grid-row: 1;
}
.item5 {
  grid-column: 1 / span 2;
  grid-row: 2;
}
.item4 {
  grid-column: 1 / span 2;
  grid-row: 3;
}
.item2 {
  grid-column: 1 / span 2;
  grid-row: 4;
}
/* .editIcon
  {
    font-size:10px;
    color:#2196F3
  }
  .deleteIcon
  {
    height:10px;
    color:red
  } */
.events-view-control {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.events-view-control button,
.events-view-control select {
  padding: 5px;
  margin: 5px;
  background: none;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
}

.events-view-control option {
  color: black;
}
.shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* popup */
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: 130px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
