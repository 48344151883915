.event-edit-form .time-container,
.event-edit-form .date-container {
  padding: 5px;
}
.event-edit-form .time-container > div,
.event-edit-form .date-container > div {
  display: flex;
  margin: 10px 0;
}
.event-edit-form .from,
.event-edit-form .to {
  display: flex;
  flex-direction: column;
}
.event-edit-form .input {
  background-color: transparent;
  padding: 5px;
}
.time {
  width: 45%;
  display: inline;
}

.button {
  text-align: center;
  background-color: #3a65ff;
  margin: 10px 5px;
  border: 1px solid #3a65ff;
  border-radius: 25px;
}
