.prayer-group-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.prayer-group-head-child {
    display: flex;
    align-items: center;
}

.player-group-table-index {
    text-align: center;
}

.prayer-group-card {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 5px;
    border: hidden;
    width: 100%;
}

.prayer-group-card table {
    border-collapse: collapse;
    width: 100%;
}

.prayer-group-card td:first-child {
    border: 4px solid #f2f5ff;
    padding: 10px;
    width: 10%;
    font-weight: bold;
    cursor: pointer;
}

.prayer-group-card td:nth-child(2) {
    border: 4px solid #f2f5ff;
    padding: 10px;
    width: 80%;
    cursor: pointer;
    font-weight: bolder;
}
.prayer-group-card td:nth-child(2):hover{
   color: #1460ac;
}
.prayer-group-card td:nth-child(3) {
    text-align: center;
    border: 4px solid #f2f5ff;
    padding: 10px;
    cursor: pointer;
}