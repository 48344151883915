.main-div{
   margin:  20px ;
   max-width: 800px;
}

.article{
    text-align: center;
    margin: 10px auto;
}

.article-title{
    margin: 10px 20px;
    display: flex;
    justify-content: space-between;
}

/* .input-article-title{
    display: flex;
    margin-top: 10px;
    min-height: 30px;
} */

.article-title-input{
    border-radius: 4px;
    border-width: 1px;
    margin-left: 2px;
    min-height: 30px;
    min-width: 300px;
    margin-top: 8px;
    
}

.article-credits-input{
    border-radius: 4px;
    border-width: 1px;
    margin-left: 2px;
    min-height: 30px;
    margin-top: 8px;
}

.article-content{
    margin: 10px 20px;
}
.article-content-input{
    margin-top: 10px;
    width: 750px;
    height: 200px;
}

.submit-btn{
   display: flex;
   justify-content: center;
}

.admin-article-sheet {
    padding: 10px;
}
.MuiButton-outlined {
    margin: 10px
  }